import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InductionLocationState } from "./induction-location.state";

export const inductionLocationFeatureKey = 'induction-location';
export const selectInductionLocationFeature = createFeatureSelector<InductionLocationState>(inductionLocationFeatureKey);
export const selectAllInductionLocations = createSelector(
  selectInductionLocationFeature,
  (inductionLocationState: InductionLocationState) => inductionLocationState.inductionLocations
);
export const selectLoadingInductionLocations = createSelector(
  selectInductionLocationFeature,
  (inductionLocationState: InductionLocationState) => inductionLocationState.loadingInductionLocations
);
export const selectInductionLocation = createSelector(selectInductionLocationFeature, (inductionLocationState: InductionLocationState) => inductionLocationState.inductionLocation);
export const selectLoadingInductionLocation = createSelector(
  selectInductionLocationFeature,
  (inductionLocationState: InductionLocationState) => inductionLocationState.loadingInductionLocation
);
export const selectInductionLocationUpdated = createSelector(
  selectInductionLocationFeature,
  (inductionLocationState: InductionLocationState) => inductionLocationState.inductionLocationUpdated
);
export const selectInductionLocationDeleted = createSelector(
  selectInductionLocationFeature,
  (inductionLocationState: InductionLocationState) => inductionLocationState.inductionLocationDeleted
);
